.hero.open-source-hero {
  h2.subtitle {
    padding-bottom: 33px;
    margin-bottom: 0;
  }

  .follow-btn {
    img,
    .text {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      margin-right: 5px;
    }
  }
}

.open-source-container {
  padding-bottom: 90px;
  margin-top: -55px;

  @include media-breakpoint-up(sm) {
    margin-top: -75px;
  }
}

.open-source-item {
  background: #ffffff;
  box-shadow: 0 32px 50px 0 rgba(142, 155, 173, 0.2);
  border-radius: 2px;
  padding: 40px 40px 70px;
  margin-bottom: 30px;
  position: relative;
  font-size: 16px;
  color: #67788e;
  letter-spacing: 0;
  line-height: 22px;

  @include media-breakpoint-up(md) {
    height: 285px;

    .inner {
      height: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    line-height: 26px;
  }

  .inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 16px;
  }

  h5, h5 a {
    font-size: 18px;
    color: #181929;
    letter-spacing: 0;
    margin-bottom: 20px;
  }

  .actions {
    position: absolute;
    bottom: 34px;
    left: 40px;
    width: 100%;

    a.help-link {
      font-size: 16px;
      color: #8898ae;
      letter-spacing: 0;
      line-height: 36px;
    }

    div[class^='styles_root'] {
      margin: 0;
      a {
        margin-right: 5px;
      }
    }
  }
}

.your-ideas {
  margin-top: 105px;

  h2 {
    font-size: 25px;
    color: #6250e5;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 45px;
    }
  }

  .subtitle {
    font-size: 18px;
    color: #8898ae;
    letter-spacing: 0;
    line-height: 28px;
  }
}
