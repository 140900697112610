.btn {
  &.btn-lg {
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0;

    &.btn-rounded {
      border-radius: 48px;
    }
  }

  &.btn-rounded {
    border-radius: 38px;
  }

  &.grad-btn {
    background-image: linear-gradient(90deg, #6250e5 0%, #2eb2ff 100%);
    border-radius: 100px;
    border-color: transparent;
  }
}
