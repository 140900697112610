#backend-developer {
  p {
    line-height: 36px;
    text-align: 'justify';
  }
  ul {
    margin-top: 20px;
    margin-bottom: 40px;
    li {
      line-height: 40px;
    }
  }
}
