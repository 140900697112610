.hero.project-hero {
  h2.subtitle {
    padding-bottom: 75px;
  }
}

.text-row {
  a {
    color: #67788e;
    font-weight: bold;
  }
}

.text-row,
.image-row {
  padding: 0 10px;

  @include media-breakpoint-up(md) {
    padding: 0 95px;
  }
}

.full-image-row,
.image-row {
  margin: 22px 0 40px;

  @include media-breakpoint-up(md) {
    margin: 42px 0 70px;
  }
}

.content-container.project-content-container {
  padding-top: 70px;
  margin-top: -170px;

  @include media-breakpoint-up(sm) {
    margin-top: -225px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 70px;
    margin-top: -225px;
  }

  h5 {
    font-size: 18px;
    color: #181929;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 28px;
  }

  .visit-project {
    position: relative;
    background-image: linear-gradient(90deg, #6250e5 0%, #2eb2ff 100%);
    margin: 0 -15px;
    padding: 33px 0 40px;

    .text-center {
      width: 100%;
    }

    .visit-title {
      font-size: 18px;
      color: #c3cbd6;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: -7px;
    }

    .link {
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      letter-spacing: 0;
      margin-bottom: -8px;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    .next-project {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -14px;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        right: 60px;
      }

      a {
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 28px;
      }

      img {
        margin-left: 14px;
      }
    }
  }
}
