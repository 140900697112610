.contact-form-container {
  padding-bottom: 190px;
  margin-top: -55px;

  @include media-breakpoint-up(sm) {
    margin-top: -195px;
  }

  .content {
    padding: 40px 80px;
    background-color: #fff;
  }

  h2 {
    font-size: 25px;
    color: #6250e5;
    letter-spacing: 0;
    line-height: 34px;
    margin-top: 32px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 45px;
    }
  }
}
